import { Progress, Tooltip, message, badge } from "ant-design-vue";
import titleCon from "@/components/title-con/index";
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";

export default {
  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
    "a-badge": badge,
    titleCon,
  },
  data() {
    return {
      greenCodeList: [],
      yellowCodeList: [],
      redCodeFirst: [],
      lmcode: 0,
      hmcode: 0,
      redCode: 0,
      //绿码
      recSwiperOptionlml: {
        initialSlide: 0,
        slidesPerView: 10,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperdylml: true,
      //黄码
      recSwiperOptionHuml: {
        initialSlide: 0,
        slidesPerView: 10,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperdyHuml: true,
      //红码
      recSwiperOptionhml: {
        initialSlide: 0,
        slidesPerView: 10,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperdyhml: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getScore();
    this.$eventBus.$emit("changeNav", { index: 1, name: "labour" });
  },
  methods: {
    async getScore() {
      //企业评分
      try {
        let query = {
          region: this.userInfo.regionCode,
          regionLevel: this.userInfo.regionLevel,
        };
        let response = await overlayApi.subjectScore(query);
        let list = response.data;
        this.greenCodeList = [];
        this.yellowCodeList = [];
        this.redCodeFirst = [];
        this.lmcode = 0;
        this.hmcode = 0;
        this.redCode = 0;
        list.forEach((element) => {
          if (element.level == 1) {
            this.lmcode = element.subjectNameAndLevel.length;
            this.greenCodeList = element.subjectNameAndLevel;
          } else if (element.level == 2) {
            this.hmcode = element.subjectNameAndLevel.length;
            this.yellowCodeList = element.subjectNameAndLevel;
          } else {
            this.redCode = element.subjectNameAndLevel.length;
            this.redCodeFirst = element.subjectNameAndLevel;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
